import Vue from 'vue'
import Router from 'vue-router'
import webPC from './pc.vue'
import webMobile from './mobile.vue'
import webHD from './HD.vue'

Vue.use(Router)

const routes = [
    // {
    //     path:"/webPC",
    //     name: 'webPC',
    //     component: webPC
    // },
    {
        path:"/",
        name: 'webPC',
        component: webPC
    },
    {
        path: "/webMobile",
        name: 'webMobile',
        component: webMobile
    },
    {
        path: "/webHD",
        name: 'webHD',
        component: webHD
    },
    // {
    //     path: '/',
    //     redirect: '/webPC'
    // }
]

const router = new Router({
    mode: 'history',
    routes
});
export default router;
