<template>
  <div>
    <div class="wiseLogoContainer">
      <img class="wiseLogoPic" src="./assets/hd/wise.png">
    </div>
    <div class="wordsContainer">
      <img class="wordsPic" src="./assets/hd/suishi.png">
    </div>
    <!--水平三个小图标-->
    <div class="flex-row" style="justify-content: space-between">
      <div style="flex-direction: column;">
        <img class="radioPic" src="./assets/hd/radio.png">
      </div>
      <div style="flex-direction: column;">
        <img class="tvPic" src="./assets/hd/tv.png">
      </div>
      <div style="flex-direction: column;">
        <img class="pointsPic" src="./assets/hd/jindou.png">
      </div>
    </div>
    <!--地标-->
    <div class="landmarkContainer">
      <img class="landmarkPic" src="./assets/hd/dibiao2.png">
    </div>
    <!--"立即打开"按钮-->
    <div class="buttonContainer">
      <img class="buttonPic" src="./assets/hd/anniu.png"  @click="clickDownloadFunction(),sendDownloadEvents()">
    </div>
  </div>
</template>

<script>

import * as location from "./js/location";
import axios from "axios";
//import QS from "qs";

export default {
  name: "HDWebsite",
  methods: {
    //点击按钮，进入下载页
    async clickDownloadFunction() {
      window.location.href = "https://a.app.qq.com/o/simple.jsp?pkgname=com.whizen.iptv.activity"
    },
    //发送下载按钮统计
    async sendDownloadEvents() {
      const ua = navigator.userAgent.toLowerCase();
      let platform = await location.getPlatform(ua);
      let sysVersion = await location.getSysVersion();
      let now = await location.getFormatDate();
      let dataArray = [];
      let data = {
        key: "wisetvWebAction",
        deviceId:"wiseWebsite",
        timestamp: new Date().getTime(),
        segmentation: {
          event: "download",
          time: now,
          sysType: "12",
          platform: platform,
          sysVersion: sysVersion,
          pageName: "wisetvWebsite"
        }
      };
      dataArray.push(data)
      //let postParams = QS.stringify({"events":JSON.stringify(dataArray)})
      let postParams = "events="+JSON.stringify(dataArray)
      await axios.post("https://data.wisetv.com.cn/i",postParams)
    },
  },
}
</script>

<style lang="stylus" rel="stylesheet/stylus" scoped>
.wiseLogoContainer{
  margin-top 9.472656vh
  margin-left  41.142vw
  width: 17.0937vw
}
.wiseLogoPic{
  width: 100%
  height 100%
}
.wordsContainer{
  margin-top 0.0892vh
  margin-left  39.495vw
  width: 21.01025vw
}
.wordsPic{
  width: 100%
  height 100%
}
.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  //margin-top 2.15053vh
  margin-left 32.284vw
  margin-right 32.284vw
}
.radioPic{
  width 5.527vw
}
.tvPic{
  width 5.527vw
}
.pointsPic{
  width 5.527vw
}
.landmarkContainer{
  //position absolute
  //margin-bottom 0vh
  //margin-top 5.15053vh
  width 100vw
  //height 40.2832vh
  z-index 10
}
.landmarkPic{
  width: 100%
  height 100%
}

.buttonContainer{
  position absolute
  top 82.6953vh
  left 32.1376vw
  //position: absolute;
  z-index 20
  width: 35.688vw
}
.buttonPic{
  width: 100%
  height 100%
}


</style>
