import {WiseTV} from "./WiseTV.js"

const callback_getUserInfo = "wise_bind_guide_jssdk_getUserInfo";
const callback_getAppInfo = "wise_bind_guide_jssdk_getAppInfo";
const callback_login_name = "wise_point_store_jssdk_login_callback";
const callback_post_request = "wise_point_store_jssdk_post_request";
const callback_openEPGDetailView = "wise_bind_guide_jssdk_openEPGDetailView";
const callback_openEPGChannel = "wise_bind_guide_jssdk_openEPGChannel";
const callback_sendCountlyEvent = "wise_bind_guide_jssdk_sendCountlyEvent";


const _do_getUserInfo = async function () {
    return new Promise((resolve) => {
        window[callback_getUserInfo] = (result) => {
            resolve(result);
        };
        WiseTV.sdk.getUserInfo(callback_getUserInfo);
    })
};
export async function getUserInfo() {
    return await _do_getUserInfo()
}

const _do_getAppInfo = async function () {
    return new Promise((resolve) => {
        window[callback_getAppInfo] = (result) => {
            resolve(result);
        };
        WiseTV.sdk.getAppInfo(callback_getAppInfo);
    })
};
export async function getAppInfo() {
    return await _do_getAppInfo()
}

const _do_sendCountlyEvent = async function (json, topic) {
    return new Promise((resolve) => {
        window[callback_sendCountlyEvent] = (result) => {
            resolve(result);
        };
        WiseTV.sdk.sendCountlyEvent(json, topic,callback_sendCountlyEvent);
    })
};
export async function sendCountlyEvent(json, topic) {
    return await _do_sendCountlyEvent(json, topic)
}

const _do_postRequest = async function (method, paramsJson) {
    return new Promise((resolve) => {
        window[callback_post_request] = (result) => {
            resolve(result);
        };
        WiseTV.sdk.doPostRequest(method, paramsJson, callback_post_request)
    })
};
export async function exchangeCard(method, userId, password) {
    let params = {};
    params.user_id = userId
    params.password = password
    return await _do_postRequest(method, params);
}

const _do_requestLogin = async function (login_level) {
    return new Promise((resolve) => {
        window[callback_login_name] = (result) => {
            resolve(result);
        };
        WiseTV.sdk.requestLogin(login_level, callback_login_name);
    })
};

export async function requestLogin(login_level) {
    return await _do_requestLogin(login_level);
}
//跳转到双屏vod详情页
const _do_openEPGDetailView = async function (vodId, vodName, mediaType, categoryId, subCategoryId) {
    return new Promise((resolve) => {
        window[callback_openEPGDetailView] = (result) => {
            resolve(result);
        };
        WiseTV.sdk.openEPGDetailView(vodId, vodName, mediaType, categoryId, subCategoryId, callback_openEPGDetailView);
    })
};

export async function openEPGDetailView(item) {
    let vodId=item.id;
    let vodName=item.titleName;
    let mediaType=item.type;
    return await _do_openEPGDetailView(vodId, vodName, mediaType, "", "");
}

//跳转到双屏频道列表页
const _do_openEPGChannel = async function () {
    return new Promise((resolve) => {
        window[callback_openEPGChannel] = (result) => {
            resolve(result);
        };
        WiseTV.sdk.openEPGChannel(callback_openEPGChannel);
    })
};

export async function openEPGChannel() {
    return await _do_openEPGChannel();
}

//组合countly字段
const _do_combineAndSend = async function (key,event,item) {
    let json={};
    if("page" == key){
        json.pageName = "iptvGuide";
    }
    json.event = event;
    if("programGuide" == event){
        json.contentType =item.contentType;
        json.contentId =item.contentId;
        json.contentName =item.contentName;
        if("seriesRec"==item.contentType){
            json.subId =item.subId;
            json.subName =item.subName;
        }
    }
    return await sendCountlyEvent(json,key);
};
export async function combineAndSend(key,event,item) {
    return await _do_combineAndSend(key,event,item);
}

//系统区分
const doGetPlatform = async function (u)  {
    if (u.match(/compatible/i) || u.match(/Windows/i)) {
        return 'windows';
    } else if (u.match(/Macintosh/i) || u.match(/MacIntel/i)) {
        return 'macOS';
    } else if (u.match(/iphone/i) || u.match(/Ipad/i)) {
        return 'ios';
    } else if (u.match(/android/i)) {
        return 'android';
    } else {
        return 'Unknown';
    }
}
export async function getPlatform(u) {
    return await doGetPlatform(u);
}

//获取系统版本
const doGetSysVersion = async function ()  {
    let u = navigator.userAgent
    let version
    if (u.indexOf('Mac OS X') > -1) {
        // ios
        let regStr_saf = /OS [\d._]*/gi
        let verinfo = u.match(regStr_saf)
        version = 'IOS' + (verinfo + '').replace(/[^0-9|_.]/ig, '').replace(/_/ig, '.')
    } else if (u.indexOf('Android') > -1 ||
        u.indexOf('Linux') > -1) {
        // android
        version = 'Android' + u.substr(u.indexOf('Android') + 8, u.indexOf(';', u.indexOf('Android')) - u.indexOf('Android') - 8)
    } else if (u.indexOf('BB10') > -1) {
        // 黑莓bb10系统
        version = 'blackberry' + u.substr(u.indexOf('BB10') + 5, u.indexOf(';', u.indexOf('BB10')) - u.indexOf('BB10') - 5)
    } else if (u.indexOf('IEMobile') > -1) {
        // windows phone
        version = 'winphone' + u.substr(u.indexOf('IEMobile') + 9, u.indexOf(';', u.indexOf('IEMobile')) - u.indexOf('IEMobile') - 9)
    } else {
        let userAgent = navigator.userAgent.toLowerCase()
        if (userAgent.indexOf('windows nt 5.0') > -1) {
            version = 'Windows 2000'
        } else if (userAgent.indexOf('windows nt 5.1') > -1 || userAgent.indexOf('windows nt 5.2') > -1) {
            version = 'Windows XP'
        } else if (userAgent.indexOf('windows nt 6.0') > -1) {
            version = 'Windows Vista'
        } else if (userAgent.indexOf('windows nt 6.1') > -1 || userAgent.indexOf('windows 7') > -1) {
            version = 'Windows 7'
        } else if (userAgent.indexOf('windows nt 6.2') > -1 || userAgent.indexOf('windows 8') > -1) {
            version = 'Windows 8'
        } else if (userAgent.indexOf('windows nt 6.3') > -1) {
            version = 'Windows 8.1'
        } else if (userAgent.indexOf('windows nt 6.2') > -1 || userAgent.indexOf('windows nt 10.0') > -1) {
            version = 'Windows 10'
        } else {
            version = 'Unknown'
        }
    }
    return version
}
export async function getSysVersion() {
    return await doGetSysVersion();
}

const doGetFormatDate = async function () {
    let datetime = new Date();//+ 8 * 60 * 60 * 1000new Date().getTime()
    let year = datetime.getUTCFullYear();
    let month = datetime.getUTCMonth()+1;//js从0开始取
    let date = datetime.getUTCDate();
    let hour = datetime.getUTCHours()+8;
    let minutes = datetime.getUTCMinutes();
    let seconds = datetime.getUTCSeconds();
    let milliSeconds= datetime.getUTCMilliseconds();
    if(month < 10){
        month = "0" + month;
    }
    if(date < 10){
        date = "0" + date;
    }
    if(hour < 10){
        hour = "0" + hour;
    }
    if(minutes < 10){
        minutes = "0" + minutes;
    }
    if(seconds < 10){
        seconds = "0" + seconds;
    }
    if(milliSeconds < 100){
        milliSeconds = "0" + milliSeconds ;
    }
    return year+month+date+hour+minutes+seconds+milliSeconds;
}
export async function getFormatDate() {
    return await doGetFormatDate();
}