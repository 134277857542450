<template>
  <div id="app">
    <!-- 对应的组件内容渲染到router-view中 -->
    <router-view></router-view>
  </div>
</template>

<script>
import axios from 'axios'
import * as location from './js/location'
//import QS from 'qs'
export default {
  name: 'App',
  async created() {
    this.toPages()
    await this.sendPageEvents()
  },
  mounted() {
  },
  methods: {
    //根据设备型号跳转不同页面
    toPages() {
      const ua = navigator.userAgent.toLowerCase();
      if (/mobile|android|iphone|phone/i.test(ua)) {
        this.$router.replace('/webMobile').catch(err => err);
      }
      if (/ipad|pad/i.test(ua) || (navigator.userAgent.match(/(iPad)/) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1))) {
        this.$router.replace('/webHD').catch(err => err);
      }
      if (/windows/i.test(ua)) {
        this.$router.replace('/').catch(err => err);
      }
    },
    //发送页面统计
    async sendPageEvents() {
      const ua = navigator.userAgent.toLowerCase();
      let platform = await location.getPlatform(ua);
      let sysVersion = await location.getSysVersion();
      let now = await location.getFormatDate();
      let dataArray = [];
      let data = {
        key: "webPage",
        deviceId:"wiseWebsite",
        timestamp: new Date().getTime(),
        segmentation: {
          time: now,
          sysType: "12",
          platform: platform,
          sysVersion: sysVersion,
          pageName: "wisetvWebsite"
        }
      };
      dataArray.push(data)
      //let postParams = QS.stringify({"events":JSON.stringify(dataArray)})
      let postParams = "events="+JSON.stringify(dataArray)
      await axios.post("https://data.wisetv.com.cn/i",postParams)
    },
  }
}

</script>

<style lang="stylus" rel="stylesheet/stylus" scoped>
</style>
