<template>
  <div>
    <div class="wiseLogoContainer">
      <img class="wiseLogoPic" src="./assets/mobile/wise1.png">
    </div>
    <div class="wordsContainer">
      <img class="wordsPic" src="./assets/mobile/suishi.png">
    </div>
    <!--水平三个小图标-->
    <div class="flex-row" style="justify-content: space-between">
      <div style="flex-direction: column;">
        <img class="radioPic" src="./assets/mobile/radio.png">
      </div>
      <div style="flex-direction: column;">
        <img class="tvPic" src="./assets/mobile/tv.png">
      </div>
      <div style="flex-direction: column;">
        <img class="pointsPic" src="./assets/mobile/jindou.png">
      </div>
    </div>
    <!--地标-->
    <div class="landmarkContainer">
      <img class="landmarkPic" src="./assets/mobile/chengshidibiao.png">
    </div>
    <!--"立即打开"按钮-->
    <div class="buttonContainer">
      <img class="buttonPic" src="./assets/mobile/anniu.png"  @click="clickDownloadFunction(),sendDownloadEvents()">
    </div>
  </div>
</template>

<script>
import * as location from "./js/location";
import axios from "axios";
//import QS from "qs";

export default {
  name: "mobile",
  async mounted() {
  },
  methods: {
    //点击按钮，进入下载页
    async clickDownloadFunction() {
      window.location.href = "https://a.app.qq.com/o/simple.jsp?pkgname=com.whizen.iptv.activity"
    },
    //发送下载按钮统计
    async sendDownloadEvents() {
      const ua = navigator.userAgent.toLowerCase();
      let platform = await location.getPlatform(ua);
      let sysVersion = await location.getSysVersion();
      let now = await location.getFormatDate();
      let dataArray = [];
      let data = {
        key: "wisetvWebAction",
        deviceId:"wiseWebsite",
        timestamp: new Date().getTime(),
        segmentation: {
          event: "download",
          time: now,
          sysType: "12",
          platform: platform,
          sysVersion: sysVersion,
          pageName: "wisetvWebsite"
        }
      };
      dataArray.push(data)
      //let postParams = QS.stringify({"events":JSON.stringify(dataArray)})
      let postParams = "events="+JSON.stringify(dataArray)
      await axios.post("https://data.wisetv.com.cn/i",postParams)
    },
  },
}
</script>

<style lang="stylus" rel="stylesheet/stylus" scoped>

.wiseLogoContainer{
  margin-top 10.0892vh
  margin-left  30.667vw
  width: 38.8vw
}
.wiseLogoPic{
  width: 100%
  height 100%
}
.wordsContainer{
  margin-top 0.0892vh
  margin-left  27.8667vw
  width: 45.867vw
}
.wordsPic{
  width: 100%
  height 100%
}
.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top 2.15053vh
  margin-left 19.8667vw
  margin-right 19.8667vw
}
.radioPic{
  width 11.0667vw
}
.tvPic{
  width 11.0667vw
}
.pointsPic{
  width 11.0667vw
}
.landmarkContainer{
  margin-top 6.0892vh
  width: 100vw
}
.landmarkPic{
  width: 100%
  height 100%
}

.buttonContainer{
  margin-top  2.7vh
  margin-left  8vw
  width 84vw
}
.buttonPic{
  width: 100%
  height 100%
}

</style>

