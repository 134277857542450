/**
 * Created by Lixu on 2015/3/19.
 */
/** WiseTV **/
(function (root) {
  root.WIESTV = root.WiseTV = root.WiseTV || {};
  root.WiseTV.VERSION = "js0.1.0";
}(window));

/** WiseTV SDK **/
(function (root) {
  root.WiseTV = root.WiseTV || {};
  var WiseTV = root.WiseTV;

  WiseTV.config = {
    schema: "Wisetv://",
    openUrl: "app.service.wisetv.com.cn",
    shareIosPage: "http://auth.wisetv.com.cn:8080/wisetvShare/share/iosMsg.html",
    shareAndroidPage: "http://auth.wisetv.com.cn:8080/wisetvShare/share/androidMsg.html",
    downloadAndroidAppUrl: 'http://shouji.baidu.com/soft/item?docid=7582519',
    downloadIosAppUrl: 'https://itunes.apple.com/cn/app/wan-shi-da/id681758808?mt=8',
  };

  WiseTV.js2Ios = function (params) {
    var iframe = document.createElement("iframe");
    iframe.src = WiseTV.config.schema + WiseTV.config.openUrl
      + '?' + WiseTV.URL.generateQueryString(params);
    iframe.style.display = 'none';
    document.body.appendChild(iframe);
  };

  // 获取用户信息
  WiseTV.getUserInfo = function (callback) {
    if (WiseTV.UA.isIphone || WiseTV.UA.ipad) {
      var params = {
        CallBackName: callback,
        FunctionName: 'LoginStatus'
      };
      WiseTV.js2Ios(params);
    }
  };

  // 登陆 level:0/1
  WiseTV.requestLogin = function (level, callback) {
    if (WiseTV.UA.isIphone || WiseTV.UA.ipad) {
      var params = {
        CallBackName: callback,
        LoginLevel: level,
        FunctionName: 'RequestUserInfor'
      };
      WiseTV.js2Ios(params);
    }
  };

  // 获取版本信息
  WiseTV.getAppInfo = function (callback) {
    if (WiseTV.UA.isIphone || WiseTV.UA.ipad) {
      var params = {
        CallBackName: callback,
        FunctionName: 'AppInfor'
      };
      WiseTV.js2Ios(params);
    }
  };

  // 兑吧登入 level:0/1 from:duiba 
  WiseTV.onUserLogin = function (level, from, callback) {
    if (WiseTV.UA.isIphone || WiseTV.UA.ipad) {
      var params = {
        CallBackName: callback,
        LoginLevel: level,
        From: from,
        FunctionName: 'RequestUserLogin'
      };
      WiseTV.js2Ios(params);
    }
  };

  // 登出
  WiseTV.requestLogout = function (callback) {
    if (WiseTV.UA.isIphone || WiseTV.UA.ipad) {
      var params = {
        CallBackName: callback,
        FunctionName: 'RequestUserLogout'
      };
      WiseTV.js2Ios(params);
    }
  };

  // 播放视频
  WiseTV.openMedia = function (queryObjectString) {
    if (!WiseTV.UA.isWiseTV || WiseTV.UA.isIphone || WiseTV.UA.ipad) {
      var params = eval('(' + queryObjectString + ')');
      params['FunctionName'] = 'OpenMedia';
      WiseTV.js2Ios(params);
    }
  };

  // 新版播放视频 支持广播
  WiseTV.playMedia = function (queryObjectString) {
    if (!WiseTV.UA.isWiseTV || WiseTV.UA.isIphone || WiseTV.UA.ipad) {
      var params = eval('(' + queryObjectString + ')');
      params['FunctionName'] = 'playMedia';
      WiseTV.js2Ios(params);
    }
  };

  // 打开分享 调用shareSDK的分享    
  WiseTV.openShare = function (programName, detail, url, imgUrl, callback) {
    if (WiseTV.UA.isIphone || WiseTV.UA.ipad) {
      var params = {
        CallBackName: callback,
        FunctionName: 'OpenShare',
        programName: programName,
        detail: detail,
        url: encodeURIComponent(encodeURIComponent(url)),
        imgUrl: imgUrl
      };
      WiseTV.js2Ios(params);
    }
  };

  // 活动分享页面，只支持分享到微信和小程序，没有type字段
  WiseTV.openShareActivity = function (name, detail, url, wxChatUrl, imgUrl, userName, callback) {
    if (WiseTV.UA.isIphone || WiseTV.UA.ipad) {
      var params = {
        CallBackName: callback,
        FunctionName: 'OpenShareActivity',
        name: name,
        detail: detail,
        wxChatUrl: encodeURIComponent(encodeURIComponent(wxChatUrl)),
        url: encodeURIComponent(encodeURIComponent(url)),
        imgUrl: imgUrl,
        userName: userName
      };
      WiseTV.js2Ios(params);
    }
  };

  // 自定义分享页面,会通过type字段返回支持的分享类型
  // type:["QQ"、"QZone"、"Wechat"、"miniProgram"、"WechatMoments"、"SinaWeibo"]
  WiseTV.openShareView = function (name, detail, url, wxChatUrl, imgUrl, userName, type, callback) {
    if (WiseTV.UA.isIphone || WiseTV.UA.ipad) {
      var params = {
        CallBackName: callback,
        FunctionName: 'OpenShareView',
        name: name,
        detail: detail,
        type: type,
        wxChatUrl: encodeURIComponent(encodeURIComponent(wxChatUrl)),
        url: encodeURIComponent(encodeURIComponent(url)),
        imgUrl: imgUrl,
        userName: userName
      };
      WiseTV.js2Ios(params);
    }
  };

  // 获取cookie
  WiseTV.getCookie = function (key, callback) {
    if (WiseTV.UA.isIphone || WiseTV.UA.ipad) {
      var params = {
        CallBackName: callback,
        FunctionName: 'GetCookie',
        key: key
      };
      WiseTV.js2Ios(params);
    }
  };

  // 设置cookie
  WiseTV.setCookie = function (key, value, callback) {
    if (WiseTV.UA.isIphone || WiseTV.UA.ipad) {
      var params = {
        CallBackName: callback,
        FunctionName: 'SetCookie',
        key: key,
        value: value
      };
      WiseTV.js2Ios(params);
    }
  };

  // 打开拍客, iOS与android实现不一致
  WiseTV.openPaiKeView = function (callback, categoryId) {
    if (WiseTV.UA.isIphone || WiseTV.UA.ipad) {
      var params = {
        CallBackName: callback,
        categoryId: categoryId,
        FunctionName: 'OpenPaiKeView'
      };
      WiseTV.js2Ios(params);
    }
  };

  // 视频上传
  WiseTV.openVideoUpload = function (callback) {
    if (WiseTV.UA.isIphone || WiseTV.UA.ipad) {
      var params = {
        CallBackName: callback,
        FunctionName: 'OpenVideoUpload'
      };
      WiseTV.js2Ios(params);
    }
  };

  // 打开聊天室
  WiseTV.openChatRoomView = function (queryObjectString) {
    if (!WiseTV.UA.isWiseTV || WiseTV.UA.isIphone || WiseTV.UA.ipad) {
      var params = eval('(' + queryObjectString + ')');
      params['FunctionName'] = 'OpenChatRoomView';
      WiseTV.js2Ios(params);
    }
  };

  // 卡包
  WiseTV.openCardBagView = function (callback) {
    if (WiseTV.UA.isIphone || WiseTV.UA.ipad) {
      var params = {
        CallBackName: callback,
        FunctionName: 'OpenCardBagView'
      };
      WiseTV.js2Ios(params);
    }
  };

  // post请求
  WiseTV.doPostRequest = function (method, paramsJson, callback) {
    if (WiseTV.UA.isIphone || WiseTV.UA.ipad) {
      var params = {
        CallBackName: callback,
        method: method,
        paramsJson: paramsJson,
        FunctionName: 'DoPostRequest'
      };
      WiseTV.js2Ios(params);
    }
  };

  // get请求
  WiseTV.doGetRequest = function (method, paramsJson, callback) {
    if (WiseTV.UA.isIphone || WiseTV.UA.ipad) {
      var params = {
        CallBackName: callback,
        method: method,
        paramsJson: paramsJson,
        FunctionName: 'DoGetRequest'
      };
      WiseTV.js2Ios(params);
    }
  };

  // get请求 新版
  WiseTV.requestGet = function (method, paramsJson, timeout, callback) {
    if (WiseTV.UA.isIphone || WiseTV.UA.ipad) {
      var params = {
        CallBackName: callback,
        method: method,
        paramsJson: paramsJson,
        timeout: timeout,
        FunctionName: 'RequestGet'
      };
      WiseTV.js2Ios(params);
    }
  };

  // post请求 新版
  WiseTV.requestPost = function (method, paramsJson, timeout, callback) {
    if (WiseTV.UA.isIphone || WiseTV.UA.ipad) {
      var params = {
        CallBackName: callback,
        method: method,
        paramsJson: paramsJson,
        timeout: timeout,
        FunctionName: 'RequestPost'
      };
      WiseTV.js2Ios(params);
    }
  };

  // 隐藏卡包核销
  WiseTV.hideTransferCard = function (callback) {
    if (WiseTV.UA.isIphone || WiseTV.UA.ipad) {
      var params = {
        CallBackName: callback,
        FunctionName: 'HideTransferCard'
      };
      WiseTV.js2Ios(params);
    }
  };
  
  // 活动列表
  WiseTV.openActivityListView = function (callback) {
    if (WiseTV.UA.isIphone || WiseTV.UA.ipad) {
      var params = {
        CallBackName: callback,
        FunctionName: 'OpenActivityListView'
      };
      WiseTV.js2Ios(params);
    }
  };

  // 活动页面
  WiseTV.openActivityView = function (title, activityUrl, callback) {
    if (WiseTV.UA.isIphone || WiseTV.UA.ipad) {
      var params = {
        CallBackName: callback,
        title: title,
        activityUrl: activityUrl,
        FunctionName: 'OpenActivityView'
      };
      WiseTV.js2Ios(params);
    }
  };

  //扫一扫
  WiseTV.openCaptureScreenView = function (type, callback) {
    if (WiseTV.UA.isIphone || WiseTV.UA.ipad) {
      var params = {
        CallBackName: callback,
        FunctionName: 'OpenCaptureScreenView'
      };
      WiseTV.js2Ios(params);
    }
  };

  // 活动统计次数
  WiseTV.activityStatistics = function (activityId, objectId, callback) {
    if (WiseTV.UA.isIphone || WiseTV.UA.ipad) {
      var params = {
        CallBackName: callback,
        activityId: activityId,
        objectId: objectId,
        FunctionName: 'ActivityStatistics'
      };
      WiseTV.js2Ios(params);
    }
  };

  // 打开分类页
  WiseTV.openCategoryView = function (categoryId, name, categoryType, showType, callback) {
    if (WiseTV.UA.isIphone || WiseTV.UA.ipad) {
      var params = {
        CallBackName: callback,
        categoryId: categoryId,
        categoryType: categoryType,
        showType: showType,
        name: name,
        FunctionName: 'openCategoryView'
      };
      WiseTV.js2Ios(params);
    }
  };

  // 开启标签视频上传view
  WiseTV.openUploadByTagView = function (tagIds, callback) {
    if (WiseTV.UA.isIphone || WiseTV.UA.ipad) {
      var params = {
        CallBackName: callback,
        FunctionName: 'OpenUploadByTagView',
        tagIds: tagIds
      };
      WiseTV.js2Ios(params);
    }
  };

  // 第一现场列表
  WiseTV.openLiveStreamView = function (topicId, callback) {
    if (WiseTV.UA.isIphone || WiseTV.UA.ipad) {
      var params = {
        CallBackName: callback,
        topicId: topicId,
        FunctionName: 'OpenLiveStreamView'
      };
      WiseTV.js2Ios(params);
    }
  };

  // 打开积分商城
  WiseTV.openIntegralMall = function (callback) {
    if (WiseTV.UA.isIphone || WiseTV.UA.ipad) {
      var params = {
        CallBackName: callback,
        FunctionName: 'openIntegralMall'
      };
      WiseTV.js2Ios(params);
    }
  };

  // IPTV 遥控器
  WiseTV.openRemoteControlView = function (callback) {
    if (WiseTV.UA.isIphone || WiseTV.UA.ipad) {
      var params = {
        CallBackName: callback,
        FunctionName: 'OpenRemoteControlView'
      };
      WiseTV.js2Ios(params);
    }
  };

  // 播放节目
  WiseTV.playProgram = function (channelId, startTime, endTime, callback) {
    if (!WiseTV.UA.isWiseTV || WiseTV.UA.isIphone || WiseTV.UA.ipad) {
      var params = {
        CallBackName: callback,
        channelId: channelId,
        startTime: startTime,
        endTime: endTime,
        FunctionName: 'PlayProgram'
      };
      WiseTV.js2Ios(params);
    }
  };

  // 开启标签视频view
  WiseTV.openTagPVODView = function (title, types, callback) {
    if (WiseTV.UA.isIphone || WiseTV.UA.ipad) {
      var params = {
        CallBackName: callback,
        FunctionName: 'OpenTagPVODView',
        title: title,
        types: types
      };
      WiseTV.js2Ios(params);
    }
  };

  // 打开频道Tab的某个子页面
  WiseTV.openChannelTabSubview = function (index, callback) {
    if (WiseTV.UA.isIphone || WiseTV.UA.ipad) {
      var params = {
        CallBackName: callback,
        index: index,
        FunctionName: 'openChannelTabSubview'
      };
      WiseTV.js2Ios(params);
    }
  };

  // 预定节目
  WiseTV.reserveMedia = function (queryObjectString) {
    if (!WiseTV.UA.isWiseTV || WiseTV.UA.isIphone || WiseTV.UA.ipad) {
      var params = eval('(' + queryObjectString + ')');
      params['FunctionName'] = 'reserveMedia';
      WiseTV.js2Ios(params);
    }
  };

  // 下载
  WiseTV.WSDownload = function (url, callback) {
    if (WiseTV.UA.isIphone || WiseTV.UA.ipad) {
      var params = {
        CallBackName: callback,
        url: url,
        FunctionName: 'WSDownload'
      };
      WiseTV.js2Ios(params);
    }
  };

  // 确认对话框
  WiseTV.showConfirmDialog = function (title, code, text, callback) {
    if (WiseTV.UA.isIphone || WiseTV.UA.ipad) {
      var params = {
        CallBackName: callback,
        FunctionName: 'ShowConfirmDialog',
        title: encodeURIComponent(title),
        text: encodeURIComponent(text),
        code: code
      };
      WiseTV.js2Ios(params);
    }
  };

  // 双屏跳转到具体分类页
  WiseTV.openEPGCategory = function (categoryId, callback) {
    if (WiseTV.UA.isIphone || WiseTV.UA.ipad) {
      var params = {
        CallBackName: callback,
        FunctionName: 'OpenEPGCategory',
        categoryId: categoryId
      };
      WiseTV.js2Ios(params);
    }
  };

  // 打开首页头部分类
  WiseTV.openHomeTopCategoryView = function (categoryId, callback) {
    if (WiseTV.UA.isIphone || WiseTV.UA.ipad) {
      var params = {
        CallBackName: callback,
        categoryId: categoryId,
        FunctionName: 'openHomeTopCategoryView'
      };
      WiseTV.js2Ios(params);
    }
  };

  // 打开观影券页面
  WiseTV.openVideoCouponView = function (callback) {
    if (WiseTV.UA.isIphone || WiseTV.UA.ipad) {
      var params = {
        CallBackName: callback,
        FunctionName: 'openVideoCouponView'
      };
      WiseTV.js2Ios(params);
    }
  };

  // 发送countly统计
  WiseTV.sendCountlyEvent = function (json, topic, callback) {
    if (WiseTV.UA.isIphone || WiseTV.UA.ipad) {
      var params = {
        CallBackName: callback,
        FunctionName: 'sendCountlyEvent',
        topic: topic,
        paramsJson: json
      };
      WiseTV.js2Ios(params);
    }
  };

  // 跳转双屏触控板
  WiseTV.openEPGControl = function (callback) {
    if (WiseTV.UA.isIphone || WiseTV.UA.ipad) {
      var params = {
        CallBackName: callback,
        FunctionName: 'openEPGControl'
      };
      WiseTV.js2Ios(params);
    }
  };

  // 跳转电视页面下的某一个标签
  WiseTV.openChannelTabSubviewByType = function(type,callback){
    if(WiseTV.UA.isIphone || WiseTV.UA.ipad){
        var params = {
            CallBackName: callback,
            FunctionName: 'openChannelTabSubviewByType',
            type:type
        };
        WiseTV.js2Ios(params);
    }
  };

  // 跳转广播页面
  WiseTV.openRadioTabView = function (callback) {
    if (WiseTV.UA.isIphone || WiseTV.UA.ipad) {
      var params = {
        CallBackName: callback,
        FunctionName: 'openRadioTabView'
      };
      WiseTV.js2Ios(params);
    }
  };

  WiseTV.openEPGDetailView = function (vodId, vodName, mediaType, categoryId, subCategoryId, callback) {
    if (WiseTV.UA.isIphone || WiseTV.UA.ipad) {
      var params = {
        CallBackName: callback,
        FunctionName: 'openEPGDetailView',
        vodId: vodId,
        vodName: vodName,
        mediaType: mediaType,
        categoryId: categoryId,
        subCategoryId: subCategoryId
      };
      WiseTV.js2Ios(params);
    }
  };

  // 跳转视达币页面并触发签到
  WiseTV.openScoreView = function (callback) {
    if (WiseTV.UA.isIphone || WiseTV.UA.ipad) {
      var params = {
        CallBackName: callback,
        FunctionName: 'openScoreView'
      };
      WiseTV.js2Ios(params);
    }
  };

  // 跳转到用户反馈
  WiseTV.openFeedbackView = function (callback) {
    if (WiseTV.UA.isIphone || WiseTV.UA.ipad) {
      var params = {
        CallBackName: callback,
        FunctionName: 'openFeedbackView'
      };
      WiseTV.js2Ios(params);
    }
  };

  //跳转到双屏电视频道列表页
  WiseTV.openEPGChannel = function (callback) {
    if (WiseTV.UA.isIphone || WiseTV.UA.ipad) {
      var params = {
        CallBackName: callback,
        FunctionName: 'openEPGChannel',
      };
      WiseTV.js2Ios(params);
    }
  };

  //跳转到双屏内某个电视频道详情
  WiseTV.openEPGChannelDetail = function (channelId,callback) {
    if (WiseTV.UA.isIphone || WiseTV.UA.ipad) {
      var params = {
        CallBackName: callback,
        ChannelId: channelId,
        FunctionName: 'openEPGChannelDetail',
      };
      WiseTV.js2Ios(params);
    }
  };

  // 跳转小程序
  WiseTV.openMiniProgram = function (programId, dir, callback) {
    if (WiseTV.UA.isIphone || WiseTV.UA.ipad) {
      var params = {
        programId: programId,
        dir: dir,
        CallBackName: callback,
        FunctionName: 'openMiniProgram'
      };
      WiseTV.js2Ios(params);
    }
  }

  var sdk = {};

  // 获取用户信息
  sdk.getUserInfo = function (callback) {
    WiseTV.getUserInfo(callback);
  };

  // 前端请求登录接口
  sdk.requestLogin = function (level, callback) {
    WiseTV.requestLogin(level, callback);
  };

  // 前端拉取app信息
  sdk.getAppInfo = function (callback) {
    WiseTV.getAppInfo(callback);
  };

  // 对吧登陆
  sdk.onUserLogin = function (level, from, callback) {
    WiseTV.onUserLogin(level, from, callback);
  };

  // 前端请求登录接口
  sdk.requestLogout = function (callback) {
    WiseTV.requestLogout(callback);
  };

  // 前端开启视频
  sdk.openMedia = function (queryString) {
    var params = WiseTV.URL.getParams(queryString);
    if (WiseTV.UA.isAndroid) {
      WiseTV.openMedia(WiseTV.config.schema.toLowerCase() + WiseTV.config.openUrl + '?' + queryString);
    } else {
      if (!WiseTV.UA.isWiseTV || WiseTV.UA.isIphone || WiseTV.UA.ipad) {
        WiseTV.openMedia(JSON.stringify(params));
      }
    }
  };

  // 前端开启视频 支持广播
  sdk.playMedia = function (queryString) {
    var params = WiseTV.URL.getParams(queryString);
    if (WiseTV.UA.isAndroid) {
      WiseTV.playMedia(WiseTV.config.schema.toLowerCase() + WiseTV.config.openUrl + '?' + queryString);
    } else {
      if (!WiseTV.UA.isWiseTV || WiseTV.UA.isIphone || WiseTV.UA.ipad) {
        WiseTV.playMedia(JSON.stringify(params));
      }
    }
  };

  // shareSDK分享
  sdk.openShare = function (programName, detail, url, imgUrl, callback) {
    WiseTV.openShare(programName, detail, url, imgUrl, callback);
  };

  // 活动页面分享
  sdk.openShareActivity = function (name, detail, url, wxChatUrl, imgUrl, userName, callback) {
    WiseTV.openShareActivity(name, detail, url, wxChatUrl, imgUrl, userName, callback);
  };

  // 自定义分享
  sdk.openShareView = function (name, detail, url, wxChatUrl, imgUrl, userName, type, callback) {
    WiseTV.openShareView(name, detail, url, wxChatUrl, imgUrl, userName, type, callback);
  };

  // 获取cookie
  sdk.getCookie = function (key, path, secure, callback) {
    var nativeKey = key + (";domain=" + root.document.domain)
      + ((path == null) ? "" : (";path=" + path))
      + ((secure == true) ? ";secure" : "");
    WiseTV.getCookie(nativeKey, callback);
  };

  // 设置cookie
  sdk.setCookie = function () {
    var argv = arguments;
    var argc = arguments.length;
    var expires = (argc > 2) ? argv[2] : null;
    var path = (argc > 3) ? argv[3] : null;
    var secure = (argc > 4) ? argv[4] : false;
    var callback = (argc > 5) ? argv[5] : function () { };
    var key = argv[0] + (";domain=" + root.document.domain)
      + ((path == null) ? "" : (";path=" + path))
      + ((secure == true) ? ";secure" : "");
    var value = escape(argv[1])
      + ((expires == null) ? "" : (";expires=" + expires.toGMTString()));
    WiseTV.setCookie(key, value, callback);
  };

  // 打开拍客
  sdk.openPaiKeView = function (callback, categoryId) {
    WiseTV.openPaiKeView(callback, categoryId);
  };

  // 上传视频
  sdk.openVideoUpload = function (callback) {
    WiseTV.openVideoUpload(callback);
  };

  // 聊天室
  sdk.openChatRoomView = function (queryString) {
    var params = WiseTV.URL.getParams(queryString);
    if (WiseTV.UA.isAndroid) {
      WiseTV.openChatRoomView(WiseTV.config.schema.toLowerCase() + WiseTV.config.openUrl + '?' + queryString);
    } else {
      if (!WiseTV.UA.isWiseTV || WiseTV.UA.isIphone || WiseTV.UA.ipad) {

        WiseTV.openChatRoomView(JSON.stringify(params));
      }
    }
  };

  // 卡包
  sdk.openCardBagView = function (callback) {
    WiseTV.openCardBagView(callback);
  };

  // POST 请求
  sdk.doPostRequest = function (method, paramsJson, callback) {
    WiseTV.doPostRequest(method, JSON.stringify(paramsJson), callback);
  };
  // 新版-前端调用服务器请求 POST 请求
  sdk.requestPost = function (method, paramsJson, timeout, callback) {
    WiseTV.requestPost(method, JSON.stringify(paramsJson), timeout, callback);
  };

  // GET 请求
  sdk.doGetRequest = function (method, paramsJson, callback) {
    WiseTV.doGetRequest(method, JSON.stringify(paramsJson), callback);
  };
  // 新版-前端调用服务器请求 GET 请求
  sdk.requestGet = function (method, paramsJson, timeout, callback) {
    WiseTV.requestGet(method, JSON.stringify(paramsJson), timeout, callback);
  };

  // 隐藏卡包核销
  sdk.hideTransferCard = function (callback) {
    WiseTV.hideTransferCard(callback);
  };

  // 活动列表
  sdk.openActivityListView = function (callback) {
    WiseTV.openActivityListView(callback);
  };

  // 活动页面
  sdk.openActivityView = function (title, activityUrl, callback) {
    WiseTV.openActivityView(title, activityUrl, callback);
  };

  // 扫一扫
  sdk.openCaptureScreenView = function (type, callback) {
    WiseTV.openCaptureScreenView(type, callback);
  };

  // 活动次数统计
  sdk.activityStatistics = function (activityId, objectId, callback) {
    WiseTV.activityStatistics(activityId, objectId, callback);
  };

  // 打开分类页面
  sdk.openCategoryView = function (categoryId, name, categoryType, showType, callback) {
    WiseTV.openCategoryView(categoryId, name, categoryType, showType, callback);
  };

  // 开启标签视频上传view
  sdk.openUploadByTagView = function (tagIds, callback) {
    WiseTV.openUploadByTagView(tagIds, callback);
  };

  // 第一现场列表
  sdk.openLiveStreamView = function (topicId, callback) {
    WiseTV.openLiveStreamView(topicId, callback);
  };

  // 打开积分商城
  sdk.openIntegralMall = function (callback) {
    WiseTV.openIntegralMall(callback);
  };

  // IPTV 遥控器
  sdk.openRemoteControlView = function (callback) {
    WiseTV.openRemoteControlView(callback);
  };

  // 播放节目
  sdk.playProgram = function (channelId, startTime, endTime, callback) {
    WiseTV.playProgram(channelId, startTime, endTime, callback);
  };

  // 开启标签视频view
  sdk.openTagPVODView = function (title, types, callback) {
    WiseTV.openTagPVODView(title, types, callback);
  };

  // 跳转电视页面下的某一个标签
  sdk.openChannelTabSubview = function (index, callback) {
    WiseTV.openChannelTabSubview(index, callback);
  };

  // 预定节目
  sdk.reserveMedia = function (queryString) {
    var params = WiseTV.URL.getParams(queryString);
    if (WiseTV.UA.isAndroid) {
      WiseTV.reserveMedia(WiseTV.config.schema.toLowerCase() + WiseTV.config.openUrl + '?' + queryString);
    } else {
      if (!WiseTV.UA.isWiseTV || WiseTV.UA.isIphone || WiseTV.UA.ipad) {
        WiseTV.reserveMedia(JSON.stringify(params));
      }
    }
  };

  // 下载
  sdk.WSDownload = function (url, callback) {
    WiseTV.WSDownload(url, callback);
  };

  // 确认对话框
  sdk.showConfirmDialog = function (title, code, text, callback) {
    WiseTV.showConfirmDialog(title, code, text, callback);
  };

  // 双屏跳转到具体分类页
  sdk.openEPGCategory = function (categoryId, callback) {
    WiseTV.openEPGCategory(categoryId, callback);
  };

  // 打开首页头部分类
  sdk.openHomeTopCategoryView = function (categoryId, callback) {
    WiseTV.openHomeTopCategoryView(categoryId, callback);
  };

  // 打开观影券页面
  sdk.openVideoCouponView = function (callback) {
    WiseTV.openVideoCouponView(callback);
  };

  // 发送countly统计
  sdk.sendCountlyEvent = function (json, topic, callback) {
    WiseTV.sendCountlyEvent(JSON.stringify(json), topic, callback);
  };

  // 跳转双屏触控板
  sdk.openEPGControl = function (callback) {
    WiseTV.openEPGControl(callback);
  };

  // 跳转电视页面下的某一个标签
  sdk.openChannelTabSubviewByType = function (type, callback) {
    WiseTV.openChannelTabSubviewByType(type, callback);
  };

  // 跳转广播页面
  sdk.openRadioTabView = function (callback) {
    WiseTV.openRadioTabView(callback);
  };
  
  // 跳转双屏vod详情页
  sdk.openEPGDetailView = function (vodId, vodName, mediaType, categoryId, subCategoryId, callback) {
    WiseTV.openEPGDetailView(vodId, vodName, mediaType, categoryId, subCategoryId, callback);
  };

  // 跳转视达币页面并触发签到
  sdk.openScoreView = function (callback) {
    WiseTV.openScoreView(callback);
  };

  // 跳转到用户反馈
  sdk.openFeedbackView = function (callback) {
    WiseTV.openFeedbackView(callback);
  };

  //跳转到双屏电视频道列表页
  sdk.openEPGChannel = function (callback) {
    WiseTV.openEPGChannel(callback);
  };

  //跳转到双屏内某个电视频道详情
  sdk.openEPGChannelDetail = function (channelId,callback) {
    WiseTV.openEPGChannelDetail(channelId,callback);
  };

  // 跳转小程序
  sdk.openMiniProgram = function (programId, dir, callback) {
    WiseTV.openMiniProgram(programId, dir, callback)
  }

  sdk.funcMap = {
    getUserInfo: {name: 'getUserInfo', desc: '获取用户信息'},
    requestLogin: {name: 'requestLogin', desc: '登陆并返回结果'},
    getAppInfo: {name: 'getAppInfo', desc: '获取版本信息'},
    onUserLogin: {name: 'onUserLogin', desc: '兑吧登陆'},
    requestLogout: {name: 'requestLogout', desc: '登出'},
    openMedia: {name: 'openMedia', desc: '播放视频(基本上支持所有类型)'},
    playMedia: {name: 'playMedia', desc: '播放视频(基本上支持所有类型，支持广播)'},
    openShare: {name: 'openShare', desc: 'shareSDK分享'},
    openShareView: {name: 'openShareView', desc: '自定义分享'},
    getCookie: {name: 'getCookie', desc: '获取cookie'},
    setCookie: {name: 'setCookie', desc: '设置cookie'},
    openVideoUpload: {name: 'openVideoUpload', desc: '上传视频'},
    openChatRoomView: {name: 'openChatRoomView', desc: '进入聊天室'},
    openCardBagView: {name: 'openCardBagView', desc: '卡包'},
    requestPost: {name: 'requestPost', desc: 'post请求新版'},
    requestGet: {name: 'requestGet', desc: 'get请求新版'},
    hideTransferCard: {name: 'hideTransferCard', desc: '隐藏卡包核销'},
    openActivityListView: {name: 'openActivityListView', desc: '全部活动列表'},
    openActivityView: {name: 'openActivityView', desc: '进入某个活动页面'},
    openCaptureScreenView: {name: 'openCaptureScreenView', desc: '扫一扫'},
    openCategoryView: {name: 'openCategoryView', desc: '打开分类页面(例:天使强档)'},
    openUploadByTagView: {name: 'openUploadByTagView', desc: '带标签视频上传界面'},
    openIntegralMall: {name: 'openIntegralMall', desc: '打开积分商城'},
    openRemoteControlView: {name: 'openRemoteControlView', desc: '双屏遥控器'},
    playProgram: {name: 'playProgram', desc: '播放节目(直播回看)'},
    openTagPVODView: {name: 'openTagPVODView', desc: '进入拍客指定标签列表'},
    openChannelTabSubviewByType: {name: 'openChannelTabSubviewByType', desc: '跳转电视某一个标签'},
    reserveMedia: {name: 'reserveMedia', desc: '预定节目'},
    WSDownload: {name: 'WSDownload', desc: '下载'},
    openEPGCategory: {name: 'openEPGCategory', desc: '双屏具体分类页'},
    openHomeTopCategoryView: {name: 'openHomeTopCategoryView', desc: '首页头部分类'},
    openVideoCouponView: {name: 'openVideoCouponView', desc: '打开观影券页面'},
    sendCountlyEvent: {name: 'sendCountlyEvent', desc: '发送countly统计'},
    openEPGControl: {name: 'openEPGControl', desc: '双屏'},
    openRadioTabView: {name: 'openRadioTabView', desc: '广播页面'},
    openEPGDetailView: {name: 'openEPGDetailView', desc: '双屏详情页'},
    openScoreView: {name: 'openScoreView', desc: '跳转视达币页面并触发签到'},
    openFeedbackView: {name: 'openFeedbackView', desc: '跳转到用户反馈'},
    openMiniProgram: {name: 'openMiniProgram', desc: '跳转到小程序'},
    openEPGChannel: {name: 'openEPGChannel', desc: '跳转到双屏电视频道列表页'},
    openEPGChannelDetail: {name: 'openEPGChannelDetail', desc: '跳转到双屏内某个电视频道详情'},
    // showConfirmDialog: {name: 'showConfirmDialog', desc: '确认对话框'},
    // openLiveStreamView: {name: 'openLiveStreamView', desc: '第一现场列表(x)'},
    // openShareActivity: {name: 'openShareActivity', desc: '活动页面分享(x)'},
    // openPaiKeView: {name: 'openPaiKeView', desc: '打开拍客(x)'},
    // doPostRequest: {name: 'doPostRequest', desc: 'post请求(x)'},
    // doGetRequest: {name: 'doGetRequest', desc: 'get请求(x)'},
    // activityStatistics: {name: 'activityStatistics', desc: '活动次数统计(x)'},
    // openChannelTabSubview: {name: 'openChannelTabSubview', desc: '跳转电视页面下的某一个标签index(x)'},
  }

  /**
   * 打开app
   */
  sdk.openWiseTV = function () {
    var self = this;
    var appUrl = WiseTV.config.shareAndroidPage;

    if (WiseTV.UA.isIphone || WiseTV.UA.ipad) {
      appUrl = WiseTV.config.shareIosPage;
    }

    if (WiseTV.UA.isWeinxin) {
      root.location.href = appUrl + root.location.search;
    } else {
      if (WiseTV.UA.isAndroid) {
        if (WiseTV.UA.weibo || WiseTV.UA.qzone || WiseTV.UA.qq
          || WiseTV.UA.txWeibo) {
          root.location.href = appUrl + root.location.search;
        } else {
          self.openMedia(root.location.href);
          setTimeout(function () {
            root.location.replace(WiseTV.config.downloadAndroidAppUrl);
          }, 1);
        }
      } else if (WiseTV.UA.isIphone || WiseTV.UA.ipad) {
        if (WiseTV.UA.qzone || WiseTV.UA.qq) {
          root.location.href = appUrl + root.location.search;
        } else {
          var params = WiseTV.URL.getParams();
          if (params['isappinstalled'] && params['isappinstalled'] === '1') {
            self.openMedia(root.location.href);
          } else {
            setTimeout(function () {
              root.location.replace(WiseTV.config.downloadAndroidAppUrl);
            }, 1);
          }
        }
      }
    }
  };

  WiseTV.sdk = sdk;
}(window));

/** WiseTV CBS **/
(function (root) {
  root.WISETV = root.WiseTV = root.WiseTV || {};
  var WiseTV = root.WISETV;
  WiseTV.CBS = {
    wrap: function (func) {
      var funName = this._randomFuncName();
      this[funName] = function () {
        func.apply(this, arguments);
      };
      return funName;
    },
    once: function (func) {
      var funName = this._randomFuncName();
      this[funName] = function () {
        func.apply(this, arguments);
        WiseTV.CBS[funName] = null;
        delete WiseTV.CBS[funName];
      };
      return funName;
    },
    _randomFuncName: function () {
      return new Date().getTime();
    },
    _before: function (func, before) {
      var wrap = func;
      func = function () {
        before();
        wrap();
      };
      return func;
    },
    _after: function (func, after) {
      var wrap = func;
      func = function () {
        wrap();
        after();
      };
      return func;
    },
    _around: function (func, before, after) {
      var wrap = func;
      func = function () {
        before();
        wrap();
        after();
      };
      return func;
    }
  };
}(window));

/** WiseTV URL **/
(function (root) {
  root.WiseTV = root.WiseTV || {};
  var WiseTV = root.WiseTV;
  var location = root.location;
  WiseTV.URL = {
    getHash: function (hash) {
      hash = hash || location.hash;
      return this._handleQueryString(hash,
        new RegExp("([^#=&]+)(=([^&]*))?", "g")) || {};
    },
    getParams: function (queryString) {
      queryString = queryString || location.search;
      return this._handleQueryString(queryString,
        new RegExp("([^?=&]+)(=([^&]*))?", "g")) || {};
    },
    _handleQueryString: function (query, reg) {
      if (query) {
        var data = {};
        query.replace(reg, function ($0, $1, $2, $3) {
          data[$1] = $3;
        });
        return data;
      }
    },
    generateQueryString: function (obj) {
      var queryString = '';
      for (var e in obj) {
        if (obj[e]) {
          queryString += e + '=' + obj[e] + '&';
        }
      }
      return queryString.substring(0, queryString.lastIndexOf('&'));
    }
  };
}(window));

/** WiseTV Tool **/
(function (root) {
  root.WiseTV = root.WiseTV || {};
  var WiseTV = root.WiseTV;
  WiseTV.Tool = {
    isFunction: function (obj) {
      return typeof obj === 'function';
    }
  };
}(window));

/** WiseTV.UA **/
(function (root) {
  root.WiseTV = root.WiseTV || {};
  var WiseTV = root.WiseTV;

  var UA = root.navigator.userAgent;
  var ua = {};
  ua['ipad'] = !!(UA.match(/(iPad).*OS\s([\d_]+)/ig));
  ua['isIphone'] = !!(!ua.ipad && UA.match(/(iPhone\sOS)\s([\d_]+)|ipod/ig));
  ua['isAndroid'] = !!(UA.match(/(Android)\s+([\d.]+)/ig));
  ua['isMobile'] = !!(ua.isIphone || ua.isAndroid);
  ua['isWeinxin'] = !!(UA.match(/MicroMessenger/ig));
  ua['weibo'] = !!(UA.match(/Weibo|weibo/ig));
  ua['qzone'] = !!(UA.match(/Qzone/ig));
  ua['txWeibo'] = !!(UA.match(/TXMicroBlog/ig));
  ua['qq'] = !!(UA.match(/QQ|V1_AND_SQ/ig));
  ua['yixin'] = !!(UA.match(/YiXin/ig));
  ua['isWiseTV'] = !!(UA.match(/WiseTV.*/ig));
  WiseTV.UA = ua;
}(window));

export const WiseTV = window.WiseTV
