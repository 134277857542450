<template>
  <!--  <div>-->
  <!--背景图片-->
  <!--    <div class="backgroundContainer">-->
  <div class="bgPic" >

    <div class="fontContainer">
      <div class="firstLineWords">
        <p>
          2018 © 万视达 www.wisetv.com.cn |
          <a  target="_blank" href="https://beian.miit.gov.cn" style="text-decoration:none;color: #FFFFFF" >&nbsp;津ICP备12007666号-1&nbsp;</a>
          | 天津网络广播电视台有限公司 | 地址 : 天津市和平区卫津路143号24层&nbsp;
        </p>
      </div>
      <div class="secondLineWords">
        <p>
          <a target="_blank" href="https://www.12377.cn/" style="text-decoration:none;color: #FFFFFF">
            中国互联网举报中心
          </a>
          | QQ 群：392544208 | 违法和不良信息举报：022-23601767
        </p>
      </div>
      <div class="thirdLineWords">
        <a target="_blank" href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=12010102000199" style="text-decoration:none;color: #FFFFFF">
          <img src="./assets/gongan_d0289dc.png">
          <span>津公网安备 12010102000199号</span>
        </a>
      </div>
    </div>


  </div>
</template>

<script>
//import axios from 'axios'
//import * as location from './js/location'
export default {
  name: "pc",
  created() {
  },
  mounted() {
  },
  methods:{
  }
}
</script>

<style lang="stylus" rel="stylesheet/stylus" scoped>


.backgroundContainer {
  //width: 100vw
  //height 100vh
  //position relative
  //overflow auto
}

.bgPic {
  position: absolute;
  top: 0;
  left: 0;
  //min-height 1440px
  //min-width 2560px
  height: 100%;
  width: 100%;
  background-image: url("./assets/pcBackground.png");
  background-position: center 0;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: 100% auto
  -webkit-background-size: cover; /* 兼容Webkit内核浏览器如Chrome和Safari */
  -o-background-size: cover; /* 兼容Opera */
  zoom 1
  z-index -1
}
.firstLineWords{

}
.secondLineWords{

}
.thirdLineWords{

}

.fontContainer{
  width: 100%
  //height: 6%
  //line-height 1vh
  font-weight:Regular;
  font-family: Source Han Sans SC;
  font-size: 14px;
  color: #FFFFFF;
  position:absolute;
  bottom:10px;
  margin 0 auto
  text-align center
  background: transparent
}

</style>

