import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import Countly from "countly-sdk-web";
// import CountlyUtil from "./CountlyUtil"
Vue.config.productionTip = false
// Vue.use(CountlyUtil,Countly,{
//   app_key: "countly",
//   url: "https://gw-test.wisetv.com.cn/",
//   max_events:'1',
//   force_post:true,
//   debug: true
// })
new Vue({
  render: h => h(App),
  router,
}).$mount('#app')
